
import { Companies, Courses, Events } from "@/api";
import {
  CompaniesWithPagination,
  Company,
  QuoteCompany,
  QuoteCompanyCourse,
  QuotePrivate
} from "@/api/types";
import TitleHeader from "@/components/common/TitleHeader.vue";
import { TableOptions } from "@/types";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    TitleHeader
  }
})
export default class CompaniesListPage extends Vue {
  headers = [
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Address",
      value: "address"
    },
    {
      text: "Lead Type",
      value: "typeName"
    },
    {
      text: "Actions",
      value: "controls",
      sortable: false,
      width: "280px"
    }
  ];
  nameFilter = "";
  page = 1;
  isPrivate = false;
  options: TableOptions = {} as TableOptions;
  courses = [];
  officeTypes: any = [];
  errors = [];
  companies: Array<Company> = [];
  companiesWithPagination: CompaniesWithPagination = {
    meta: {
      perPage: 10
    }
  };
  timeout = 0;
  menuCompany = false;
  menuPrivate = false;
  currentDate = moment()
    .add(1, "days")
    .format("YYYY-MM-DD");

  initialCourse = {
    courseId: undefined,
    qtyDelegates: undefined,
    discountedPrice: undefined,
    coursePrice: undefined
  };

  quotePrivate: QuotePrivate = {
    fullName: "",
    personId: "",
    companyId: undefined,
    typeId: 1,
    courses: [{ ...this.initialCourse }]
  };

  quoteCompany: QuoteCompany = {
    companyName: "",
    address: "",
    courses: [{ ...this.initialCourse }],
    coursePrice: undefined,
    discountedPrice: undefined,
    premiseTypeId: 2,
    travelCost: undefined,
    accomodationCost: undefined,
    instructorFee: undefined,
    lunchFee: undefined,
    courierFee: undefined,
    carHireFee: undefined,
    companyId: undefined,
    typeId: 2,
    comments: ""
  };

  @Watch("options", { deep: true })
  async onOptionsChange(val: any, old: any) {
    if (old !== val) await this.loadCompaniesData(val.page);
  }

  @Watch("quoteCompany.premiseTypeId", { deep: true })
  async premiseChange(val: any) {
    if (val !== 2) {
      this.quoteCompany.accomodationCost = undefined;
      this.quoteCompany.travelCost = undefined;
    }
  }

  @Watch("nameFilter")
  async onFilterNameChange() {
    window.clearTimeout(this.timeout);
    this.timeout = window.setTimeout(async () => {
      // if (val.length >= 3) {
      await this.loadCompaniesData(1);
      // } else {
      // await this.loadCompaniesData(1);
      // }
      this.options.page = 1;
    }, 500);
  }

  async setCoursePrice(course: QuoteCompanyCourse) {
    if (course.courseId) {
      const selectedCourse = await this.onCourseUpdate(course.courseId);
      course.coursePrice = selectedCourse;
    }
  }
  addCourse(company: any) {
    company.courses.push({ ...this.initialCourse });
  }
  removeCourse(
    company: QuotePrivate | QuoteCompany,
    courseToDelete: QuoteCompanyCourse
  ) {
    company.courses = company.courses.filter(
      course => course !== courseToDelete
    );
  }

  async created() {
    try {
      [this.courses, this.officeTypes] = await Promise.all([
        Courses.listWithoutPagination(),
        Events.officeTypes()
      ]);
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }

  async loadCompaniesData(page = 1) {
    try {
      const companiesWithPagination = await Companies.list(
        page,
        this.nameFilter
      );
      this.companies = companiesWithPagination.data
        ? companiesWithPagination.data
        : [];
      this.companiesWithPagination = companiesWithPagination;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }

  async onCreate() {
    await this.$router.push({ name: "CompaniesCreate" });
  }

  async onDelete(id: number) {
    const res = await this.$dialog.error({
      text: "Do you really want to delete item?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      try {
        await Companies.remove(id.toString());
        await this.loadCompaniesData(this.page);
        this.nameFilter = "";
        await this.$success(
          "<strong>Success!</strong> Company has been deleted!"
        );
      } catch (e) {
        const err = e as any;
        await this.$error(err.errors);
      }
    }
  }

  async onEdit(id: number, companyType: string) {
    await this.$router.push({
      name: "CompaniesEdit",
      params: {
        id: id.toString(),
        companyType: companyType
      }
    });
  }

  async onView(id: number) {
    await this.$router.push({
      name: "CompaniesView",
      params: { id: id.toString() }
    });
  }

  async createQuote(id: string, companyType: boolean) {
    this.isPrivate = companyType;
    this.$modal.show("quote-modal");
    if (!companyType) {
      const companyInfo = await Companies.info(id);
      this.quoteCompany.companyName = companyInfo.name;
      this.quoteCompany.address = companyInfo.address;
      this.quoteCompany.companyId = id;
    } else {
      const companyInfoPrivate = await Companies.infoPrivate(id);
      this.quotePrivate.fullName = companyInfoPrivate.name;
      this.quotePrivate.personId = companyInfoPrivate.passport;
      this.quotePrivate.companyId = id;
    }
  }

  async onCloseModal() {
    this.$modal.hide("quote-modal");
    this.isPrivate = false;
    this.quoteCompany = {
      companyName: "",
      address: "",
      courses: [{ ...this.initialCourse }],
      coursePrice: undefined,
      discountedPrice: undefined,
      premiseTypeId: 2,
      travelCost: undefined,
      accomodationCost: undefined,
      instructorFee: undefined,
      lunchFee: undefined,
      courierFee: undefined,
      carHireFee: undefined,
      companyId: undefined,
      typeId: 2,
      comments: ""
    };
    this.quotePrivate = {
      fullName: "",
      personId: "",
      companyId: undefined,
      typeId: 1,
      courses: [{ ...this.initialCourse }]
    };
  }

  async transformEmptyValues() {
    if (this.isPrivate) {
      // this.quotePrivate.courses.forEach(course => {
      //   course.discountedPrice = course.discountedPrice
      //     ? course.discountedPrice
      //     : 0;
      // });
    } else {
      // this.quoteCompany.courses.forEach(course => {
      //   course.discountedPrice = course.discountedPrice
      //     ? course.discountedPrice
      //     : 0;
      //   course.qtyDelegates = course.qtyDelegates ? course.qtyDelegates : 1;
      // });
      this.quoteCompany.travelCost = this.quoteCompany.travelCost
        ? this.quoteCompany.travelCost
        : 0;
      this.quoteCompany.accomodationCost = this.quoteCompany.accomodationCost
        ? this.quoteCompany.accomodationCost
        : 0;
      this.quoteCompany.instructorFee = this.quoteCompany.instructorFee
        ? this.quoteCompany.instructorFee
        : 0;
      this.quoteCompany.lunchFee = this.quoteCompany.lunchFee
        ? this.quoteCompany.lunchFee
        : 0;
      this.quoteCompany.courierFee = this.quoteCompany.courierFee
        ? this.quoteCompany.courierFee
        : 0;
      this.quoteCompany.carHireFee = this.quoteCompany.carHireFee
        ? this.quoteCompany.carHireFee
        : 0;
    }
  }

  async postCreateQuote() {
    try {
      const valid: any = this.isPrivate
        ? await (this.$refs.privateObserver as Vue & {
            validate: () => boolean;
          }).validate()
        : await (this.$refs.companyObserver as Vue & {
            validate: () => boolean;
          }).validate();

      if (valid) {
        await this.transformEmptyValues();

        const data = this.transformDataToRequestBody();

        await Companies.createQuote(data).then(() => {
          this.$success("<strong>Success!</strong>Quote has been created!");
          this.onCloseModal();
        });
      }
    } catch (e) {
      const err = e as any;
      const errText = JSON.parse(await err.response.data.text());

      await this.$error(
        (errText?.result?.message && { err: errText.result.message }) || {
          err: "Something went wrong"
        }
      );
    }
  }

  async postPreviewQuote() {
    try {
      const valid: any = this.isPrivate
        ? await (this.$refs.privateObserver as Vue & {
            validate: () => boolean;
          }).validate()
        : await (this.$refs.companyObserver as Vue & {
            validate: () => boolean;
          }).validate();

      if (valid) {
        await this.transformEmptyValues();

        const data = this.transformDataToRequestBody();

        await Companies.previewQuote(data).then((response: any) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, "_blank");
        });
      }
    } catch (e) {
      const err = e as any;
      const errText = JSON.parse(await err.response.data.text());

      await this.$error(
        (errText?.result?.message && { err: errText.result.message }) || {
          err: "Something went wrong"
        }
      );
    }
  }

  transformDataToRequestBody() {
    const quote: QuoteCompany | QuotePrivate = this.isPrivate
      ? this.quotePrivate
      : this.quoteCompany;

    const courses = [...JSON.parse(JSON.stringify(quote.courses))];

    const data = JSON.parse(JSON.stringify(quote));

    data.courses = courses.reduce((acc, cur) => {
      if (acc[cur.courseId] && acc[cur.courseId].length) {
        acc[cur.courseId].push({
          price: cur.discountedPrice ? cur.discountedPrice : cur.coursePrice,
          qtyDelegates: cur.qtyDelegates
        });
      } else {
        acc[cur.courseId] = [
          {
            price: cur.discountedPrice ? cur.discountedPrice : cur.coursePrice,
            qtyDelegates: cur.qtyDelegates
          }
        ];
      }

      return acc;
    }, {});
    return data;
  }

  async toggleForms(isPrivate: boolean) {
    this.isPrivate = isPrivate;
  }

  async onCourseUpdate(id: number) {
    try {
      const course = await Courses.info(id.toString());
      return course.price;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
}
